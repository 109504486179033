import React, { useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import Checkbox from '../../../common/checkbox';

export default function NewFollowup(props) {
    const { insertNewFollowUpDate } = ReportApi()
    const { reportId } = props
    const [selectedValue, setSelectedValue] = useState(null);
    
    const handleCheckboxChange = (value) => {
        setSelectedValue(value);
    };

    const navigate = useNavigate()

    const handleShow = () => {
        navigate(`summary`)
    }

    const addNode = () => {
        const bodyData = {
            "new_follow_up": selectedValue,
        }
        insertNewFollowUpDate({ reportId }, bodyData)
        toast.success("Saved Successfully!")
    }

    return (
        <div>
            <div align='left'>
                <div className="form-group">
                    Next Follow-Up
                </div>
                <div >
                    <Checkbox
                        selectedValue={selectedValue}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>

            <div className="row float-right">
                <div className="text-left mt-2">
                    <MainButtonInput onClick={addNode}> Save</MainButtonInput>
                </div>
                <div className="text-left ml-2 mt-2">
                    <SecondaryButtonInput onClick={handleShow}>Summary</SecondaryButtonInput>
                </div>
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </div>
    )
}