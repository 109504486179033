
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Treatment } from "./treatment";
import ServicesApi from "../../../services/servicesAPI";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRecoilState } from "recoil";
import { setAddTreatment } from "../../../recoil/atom/setAddTreatement";
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateTreatment } from "./updateTreatment";
function AddTreatment(props) {
    const { doctorId } = props
    const [showTreatment, setShowTreatment] = useState(false);
    const [showUpdateTreatment, setShowUpdateTreatment] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [serviceId, setServiceId] = useState(false);
    const [services, setServices] = useRecoilState(setAddTreatment);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getServices, deleteServices } = ServicesApi()
    const [isLoading, setIsLoading] = useState(true);

    const pageSize = 5;

    useEffect(() => {
        fetchServices(currentPage)
    }, [currentPage]);

    const handleTreatmentClose = () => setShowTreatment(false)
    const handleTreatmentShow = () => setShowTreatment(true)

    const onCTreatmentFormSubmit = () => {
        handleTreatmentClose()
    };

    const handleUpdateTreatmentClose = () => setShowUpdateTreatment(false);

    const handleUpdateTreatmentShow = (id) => {
        setServiceId(id)
        setShowUpdateTreatment(true)
    };

    const onUpdateTreatmentFormSubmit = () => {
        handleUpdateTreatmentClose()
    };

    const handleDeleteClose = () => setShowDelete(false);

    const handleDeleteShow = (id) => {
        setServiceId(id)
        setShowDelete(true)
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    const fetchServices = (currentPage) => {
        setIsLoading(true);
        getServices(doctorId, currentPage, pageSize)
            .then((res) => {
                setServices(res.data)
                setTotalPages(res.totalPages)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function deleteService(id) {
        deleteServices(id)
            .then(() => {
                fetchServices()
                handleDeleteClose()
            })
    }

    return (
        <div >
            <div className="modalbtn">
                <Modal show={showTreatment} onHide={handleTreatmentClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Treatment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Treatment doctorId={doctorId} onSubmit={onCTreatmentFormSubmit} />
                    </Modal.Body>
                </Modal>
            </div>

            <div className="modalbtn">
                <Modal show={showUpdateTreatment} onHide={handleUpdateTreatmentClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Treatment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UpdateTreatment
                            doctorId={doctorId}
                            serviceId={serviceId}
                            onSubmit={onUpdateTreatmentFormSubmit}
                        />
                    </Modal.Body>
                </Modal>
            </div>

            <div align='right'>
                <MainButtonInput className='align-left' onClick={handleTreatmentShow}>ADD TREATMENT</MainButtonInput>
            </div>
            <>
              {/* {isLoading?
                 <div className='loader-container'>
                    <Loader/>
                 </div>
                 : */}
                    <>
                        {services && services.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font_weight" align="left">Sr. No.</TableCell>
                                            <TableCell className="font_weight" align="left">service Name</TableCell>
                                            <TableCell className="font_weight" align="left">Fees </TableCell>
                                            <TableCell className="font_weight" align="left">Actions </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {services && services.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {index + 1}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.name}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.fees}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Link onClick={() => handleDeleteShow(item._id)}>
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Link>
                                                        <Link onClick={() => handleUpdateTreatmentShow(item._id)}>
                                                            <ModeEditOutlineSharpIcon style={{ marginLeft: 15, fontSize: 20 }} />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer >
                            :
                            <div className="clinicHistory font_weight" >Add your special treatments</div>
                        }
                    </>
                    {/* } */}
            </>
            <div className="mt-5">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination "
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                />
            </div>
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to delete this service.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteService(serviceId)}>
                            Yes
                        </Button>
                        <Button variant="default" style={{ border: '1px solid #1a3c8b' }} onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >

    )
}

export { AddTreatment }