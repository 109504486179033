import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { MainButtonInput } from "../mainComponent/mainButtonInput";
import { MainInput } from "../mainComponent/mainInput";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { setHelperData } from "../recoil/atom/setHelperData";
import { MainNav } from "../mainComponent/mainNav";
import { Wrapper } from "../mainComponent/Wrapper";
import PatientApi from "../services/PatientApi";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { LoginPatientOtp } from "./loginPatientOtp";

export default function PatientLoginForm() {
    const [mobile, setMobile] = useState("");
    const [isError, setIsError] = useState(false);
    const [doctorId, setdoctorId] = useRecoilState(setDoctorId);
    const [patientId, setPatientId] = useRecoilState(setNewPatientId);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [showOTP, setShowOTP] = useState(false)
    const [loginData, setLoginData] = useState([])
    const { loginPatient } = PatientApi()
    const navigate = useNavigate()

    const getOTPSection = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError(true)
        }
        else {
            loginPatient({ mobile })
                .then(data => {
                    if (data.data.isLoggedIn !== true) {
                        alert(data.data.otp)
                        setShowOTP(true)
                        setIsError(false)
                    }
                    else {
                        setIsError(true)
                    }
                    setPatientId(data.data._id)
                    let item = data.data
                    setLoginData(item)
                })
        }
    }
    const backButton = () => {
        navigate(-1)
    }
    
    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link onClick={backButton}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Create Account</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className="width_84 common_box">
                    <div className="container margin_60_35">
                        <div id="login-2">
                            <h1 className="mb-3 primary-text-color" align='left'>Create Patient Account</h1>
                            <form>
                                <div className=" clearfix">
                                    <div className=" last">
                                        <div className="row">
                                            <lable className='mb-2'>Mobile Number</lable>
                                            <div className="col-md-12">
                                                <MainInput
                                                    name="mobile"
                                                    value={mobile.mobile}
                                                    type="text"
                                                    maxLength={10}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    placeholder="Phone Number (+XX)" >
                                                </MainInput>
                                                <div align='right' className="validation ">{isError}</div>
                                            </div>
                                        </div>
                                        {showOTP === true ?
                                            <>
                                                <LoginPatientOtp loginData={loginData} />
                                                <Outlet />
                                            </>
                                            : <div align='left'>
                                                <MainButtonInput onClick={getOTPSection}>Go</MainButtonInput>
                                            </div>
                                        }
                                    </div>

                                    {isError === true ?
                                        <div className="validation mb-2" >
                                            Please enter valid mobile number.
                                        </div>
                                        : null
                                    }
                                </div>
                            </form>
                            <div className="appcolor" align='right'>
                                <Link onClick={backButton}>
                                    Already have account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper >

    )
}