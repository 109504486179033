import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import AppointmentsApi from '../../services/AppointmentsApi';
import moment from 'moment';
import Person from '@mui/icons-material/Person';
import { Modal } from 'react-bootstrap';
import CalendarModalBox from './partial/CalendarModalBox';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export default function DateCalendarValue(props) {
    const { doctorId } = props;
    const [value, setValue] = useState(dayjs());
    const [events, setEvents] = useState([]);
    const [selectedDateEvents, setSelectedDateEvents] = useState(null);
    const [patientAppointment, setPatientAppointment] = useState([]);
    const [patientIdDetails, setPatientIdDetails] = useState([]);
    const [show, setShow] = useState(false);
    const { getAppointmentsDetails } = AppointmentsApi();
    const isMobile = useMediaQuery('(max-width: 768px)');

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = () => {
        getAppointmentsDetails(doctorId)
            .then((result) => {
                const data = result['ongoingProduct'];
                const calendarData = [];
                data && data.map((item) => {
                    const startDate = new Date(item.startDate);
                    const endDate = new Date(moment(item.startDate).add({ hours: 0, minutes: item.timeSlot }).toString());
                    calendarData.push({
                        title: item.dependentId ? item['dependentDetails'][0].name : item['patientDetails'][0].name,
                        patientId: item.dependentId ? item['dependentDetails'][0]._id : item['patientDetails'][0]._id,
                        id: item._id,
                        start: startDate,
                        end: endDate,
                        timeslots: item.timeSlot,
                        status: item.status,
                    });
                    setPatientAppointment(item)
                });
                setEvents(calendarData);
                fetchEventsForDate(dayjs().format('YYYY-MM-DD'));
            });
    };

    const fetchEventsForDate = (date) => {
        const filteredEvents = events
            .filter(event => dayjs(event.start).format('YYYY-MM-DD') === date && event.status === 'Ongoing');
        setSelectedDateEvents(filteredEvents);
    };

    const handleModalButtonClick = (item) => {
        setPatientIdDetails(item.patientId);
        setShow(item.status === 'Ongoing');
    };

    const handleClose = () => setShow(false);

    return (
        <div className='col-lg-8'>
            <div className="white-box ">
                <div className="row">
                    {isMobile ? (
                        <Accordion defaultActiveKey="0">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="calendar-content"
                                id="calendar-header"
                            >
                                <h5>Appointments</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                        <DemoItem>
                                            <DateCalendar
                                                value={value}
                                                onChange={(newValue) => {
                                                    setValue(newValue);
                                                    fetchEventsForDate(dayjs(newValue).format('YYYY-MM-DD'));
                                                }}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        // Calendar for desktop

                        <div className="col-md-6 calendar-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <DateCalendar
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                                fetchEventsForDate(dayjs(newValue).format('YYYY-MM-DD'));
                                            }}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>


                    )}

                    {/* <div className='col-xl-6 rborder mb-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem >
                                <DateCalendar
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                        fetchEventsForDate(dayjs(newValue).format('YYYY-MM-DD'));
                                    }}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </div> */}

                    <div className='col-md-6'>
                        <div>
                            {selectedDateEvents && selectedDateEvents.length > 0 ? (
                                <>
                                    {selectedDateEvents.map((item) => (
                                        <div
                                            key={item.id}
                                            className="calendarEvents row width_100"
                                            onClick={() => handleModalButtonClick(item)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className='fontSize color width_50 lineHeight' align='left'>
                                                <Person className="personIcon fontSize color" />{item.title}
                                            </div>
                                            <div className='fontWeight color lineHeight width_50' align='right'>
                                                {dayjs(item.start).format(' h:mm A')}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (<p className='.MuiTypography-h6 mt-3'>No appointments available. Please select another day.</p>)}
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Patient Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CalendarModalBox
                                doctorId={doctorId}
                                patientId={patientIdDetails}
                                patientAppointment={patientAppointment}
                                onSubmit={handleModalButtonClick}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    );
}