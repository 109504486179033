import { React } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { SetTiming } from "./setTiming";
import { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import { SetDoctorSessionTiming } from "../../../../recoil/atom/SetDoctorSessionTiming";
import { updateSession } from '../../../../recoil/atom/setUpdateSession'
import { Icon } from '@mui/material';
import SetUpdateTime from "./setUpdateTime";
import moment from 'moment';
import SessionApi from '../../../../services/SessionApi';

function SetSession(props) {
    const { doctorId, clinicId, onSubmit } = props;
    const [dayName, setDayNames] = useState();
    const [showtime, setShowTime] = useState(false);
    const [updateTime, setUpdateTime] = useState(false);
    const [fetchTime, setfetchTime] = useRecoilState(SetDoctorSessionTiming);
    const [fetchUpdateTime, setfetchUpdateTime] = useRecoilState(updateSession);
    const [updateItem, setUpdateItem] = useState([]);
    const [deleteItem, setDeleteItem] = useState([]);
    const [Item, setItem] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const { allSessions, deleteSlot } = SessionApi()

    useEffect(() => {
        getAllSession()
    }, [])

    const dayList = {
        "mon": "Mon",
        "tue": "Tue",
        "wed": "Wed",
        "thu": "Thu",
        "fri": "Fri",
        "sat": "Sat",
        "sun": "Sun"
    }
    const daysKeys = Object.keys(dayList)

    const handleClose = () => setShowTime(false);

    const handleShow = (e, day) => {
        e.preventDefault();
        setShowTime(true)
        setDayNames(day)
    };
    const handleDeleteShow = (item) => {
        setItem(item)
        setShowDelete(true)
    }
    //setTiming component
    const handleTimeClick = () => {
        handleClose();
    };

    const handleUpdateClose = () => setUpdateTime(false);

    const handleDeleteClose = () => setShowDelete(false);

    const handleUpdate = (e, item) => {
        e.preventDefault();
        setUpdateTime(true);
        setUpdateItem(item)
    }

    const handleUpdateTimeClick = () => {
        handleUpdateClose();
    }

    function getAllSession() {
        const dataId = {
            doctorId: doctorId,
            clinicId: clinicId,
            isDeleted: false
        }
        allSessions(dataId)
            .then(jsonRes => {
                setDeleteItem(jsonRes)
                let byDay = jsonRes.reduce((allDayData, singleDayData) => {
                    allDayData[singleDayData.day] = [...allDayData[singleDayData.day] || [], singleDayData];
                    return allDayData;
                }, {});
                setfetchTime(byDay)
                setfetchUpdateTime(byDay)
            });
    }

    const deleteSlotData = (Item) => {
        if(deleteItem){
            const deleteData = deleteItem.filter((i) => {
                if (i.day === Item) {
                    return i
                }
            })
            const slotId = deleteData[0]._id
            deleteSlot(slotId)
                .then(() => {
                    getAllSession()
                    handleDeleteClose()
                })
        }else{
            return null
        }
       
    }
    return (
        <div>
            <ul>
                {daysKeys && daysKeys.map((item, index) =>
                    <li key={index}>
                        <div className="bottomborder width_100">
                            <div className="row">
                                <div className="width_25 rightborder">
                                    <div className=" p-2 ml-2 ">
                                        {dayList[item]}
                                    </div>
                                </div>

                                {fetchUpdateTime[item]
                                    ?
                                    <>
                                        <div className="width_60 p-2" >
                                            <div className='width_100'>
                                                <Link onClick={(e) => handleUpdate(e, fetchUpdateTime[item])} >
                                                    <span >
                                                        {moment(new Date(fetchUpdateTime[item][0].fromTime)).format("HH:mm")}
                                                        -
                                                        {moment(new Date(fetchUpdateTime[item][0].toTime)).format("HH:mm")}
                                                        <span className='ml-3'>
                                                            {fetchUpdateTime[item][0].fees}/-
                                                        </span>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                        <span className="width_10">
                                            <Link to="#" onClick={() => handleDeleteShow(item)}>
                                                <Icon className="icon-trash-2" style={{ fontSize: 17, marginTop: 7 }} ></Icon>
                                            </Link>
                                        </span>
                                    </>
                                    : (
                                        <>
                                            {fetchTime[item]
                                                ?
                                                <>
                                                    <div className="width_60 p-2" >
                                                        <Link onClick={(e) => handleUpdate(e, fetchTime[item])} >
                                                            <span>
                                                                {moment(new Date(fetchTime[item][0].fromTime)).format("HH:mm")}
                                                                -
                                                                {moment(new Date(fetchTime[item][0].toTime)).format("HH:mm")}
                                                                <span className='ml-3'>
                                                                    {fetchTime[item][0].fees}/-
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <span className="width_10">
                                                        <Link to="#" onClick={() => handleDeleteShow(item)}>
                                                            <Icon className="icon-trash-2" style={{ fontSize: 17, marginTop: 7 }} ></Icon>
                                                        </Link>
                                                    </span>
                                                </>
                                                :
                                                <div className="width_60 p-2">
                                                    <Link to="#" onClick={(e) => handleShow(e, item)} className="">
                                                        Set Session Timing
                                                    </Link>
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </li>
                )}
            </ul>

            <Modal show={showtime} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SetTiming doctorId={doctorId} clinicId={clinicId} day={dayName} onSubmit={handleTimeClick} />
                </Modal.Body>
            </Modal>
            <Modal show={updateTime} onHide={handleUpdateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SetUpdateTime doctorId={doctorId} day={dayName} update={updateItem} onSubmit={handleUpdateTimeClick} />
                </Modal.Body>
            </Modal>
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to delete this session.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteSlotData(Item)}>
                            Yes
                        </Button>
                        <Button variant="default" style={{ border: '1px solid #1a3c8b' }} onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}
export { SetSession }