import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactPaginate from "react-paginate";
import LabWorkApi from '../../../services/LabWorkApi';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import { Modal, Button } from "react-bootstrap";
import LabWorkView from '../report/LabWorkView';
import UpdateLabWorkTable from './updateLabworktable';
import { useRecoilState } from 'recoil';
import { setLabworkData } from '../../../recoil/atom/setLabWorkData';
import DeleteIcon from '@mui/icons-material/Delete';

export default function GetLabWork(props) {
    const { doctorId } = props
    const [labWorkData, setLabWorkData] = useRecoilState(setLabworkData);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [showData, setShowData] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showLabData, setShowLabData] = useState(false);
    const [labWorkId, setLabWorkId] = useState(false);
    const pageSize = 5;
    const { getlabworkbydoctorId , deleteLabWork} = LabWorkApi()

    useEffect(() => {
        getLabWorkData(currentPage);
    }, [currentPage])

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    const handleDataClose = () => setShowData(false)

    const handleDataShow = (id) => {
        setLabWorkId(id)
        setShowData(true)
    }

    const onDataFormSubmit = () => {
        handleDataClose()
    };

    const handleLabDataClose = () => setShowLabData(false)

    const handleLabDataShow = (id) => {
        setLabWorkId(id)
        setShowLabData(true)
    }

    const onDataLabFormSubmit = () => {
        handleLabDataClose()
    };

    const handleDeleteClose = () => setShowDelete(false);

    const handleDeleteShow = (id) => {
        setLabWorkId(id)
        setShowDelete(true)
    }
    
    const getLabWorkData = (currentPage) => {
        getlabworkbydoctorId(doctorId, currentPage, pageSize)
            .then((result) => {
                if(result.data){
                    setTotalPages(result.totalPages)
                    setLabWorkData(result.data)
                }else{
                    return null
                }
            })
    }

    function deleteLabwork(id) {
        deleteLabWork(id)
            .then(() => {
                getLabWorkData()
                handleDeleteClose()
            })
    }
    return (
        <>
            {labWorkData && labWorkData.length > 0 ? <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="font_weight" align="left">Work Name</TableCell>
                                <TableCell className="font_weight" align="left">Work Type</TableCell>
                                <TableCell className="font_weight" align="left">Lab Name</TableCell>
                                <TableCell className="font_weight" align="left">Lab Contact</TableCell>
                                <TableCell className="font_weight" align="left">Given Date</TableCell>
                                <TableCell className="font_weight" align="left">View Details</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {labWorkData && labWorkData.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            {item.workname}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.worktype}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.labname}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.labcontact}
                                        </TableCell>

                                        <TableCell align="left">
                                            {moment(item.givendate).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell align="left">
                                            <Link onClick={() => handleDataShow(item._id)}>
                                                <VisibilityIcon style={{ fontSize: 20 }} />
                                            </Link>
                                            <Link onClick={() => handleLabDataShow(item._id)}>
                                                <ModeEditOutlineSharpIcon style={{marginLeft: 15, fontSize: 20 }} />
                                            </Link>
                                            <Link onClick={() => handleDeleteShow(item._id)}>
                                                <DeleteIcon style={{marginLeft: 15, fontSize: 20 }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer >
                <div className='mt-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination "
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </> : <div className="clinicHistory font_weight" >Add your work here</div>}
            <Modal show={showData} onHide={handleDataClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Work Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabWorkView 
                        labWorkId={labWorkId} 
                        onSubmit={onDataFormSubmit} 
                    />
                </Modal.Body>
            </Modal>
            <Modal show={showLabData} onHide={handleLabDataClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Work Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateLabWorkTable 
                        doctorId={doctorId} 
                        labWorkId={labWorkId} 
                        onSubmit={onDataLabFormSubmit} 
                    />
                </Modal.Body>
            </Modal>
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to delete this labwork.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteLabwork(labWorkId)}>
                            Yes
                        </Button>
                        <Button variant="default" style={{ border: '1px solid #1a3c8b' }} onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </>
    )
} 