import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setFetchSymptoms } from '../../../recoil/atom/setFetchSymptoms';

export default function GetSymptomsData(props) {
    const { reportId } = props
    const { getMedicineReport } = ReportApi();
    const [fetchSymptoms, setfetchSymptoms] = useRecoilState(setFetchSymptoms)
    
    useEffect(() => {
        symptomsData()
    }, [])

    const symptomsData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                if(res){
                    setfetchSymptoms(res[0].symptoms)
                }
            })
    }

    return (
        <div align='left'>
            {fetchSymptoms && fetchSymptoms.length > 0 ?
                <div className='margin_top_15' align='left'>
                    <h6  className='patientModal'>List of Symptoms</h6>
                    {fetchSymptoms && fetchSymptoms.map((item, i) => {
                        return (
                            <span key={i}>
                                {item}<br />
                            </span>
                        )
                    })}
                </div>
                : null}
        </div>
    )
}