import React, { useEffect, useState } from "react";
import ReportApi from "../../../services/ReportApi";
import Toaster from "../../Toaster";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";

export default function Premedication(props) {
    const { insertPremedicationNote, getMedicineReport } = ReportApi();
    const [premedication_note, setPremedication_note] = useState('')
    const [premedication, setPremedication] = useState('')
    const { onChange, reportId } = props;

    useEffect(() => {
        premedicationData()
    }, [])
    
    const handleChange = (event) => {
        setPremedication_note(event.target.value);
    }

    const addNode = () => {
        const bodyData = {
            "premedication_note": premedication_note,
        }
        insertPremedicationNote({ reportId }, bodyData)
            .then(() => {
            })
            toast.success("Saved Successfully!")
    }
    const premedicationData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setPremedication(res[0].premedication_note)
            })
    }
    return (
        <>
            <div align='left' >
                <span className=' mb-2'>Doctor Premedication Note</span>
                <textarea
                    type="text"
                    value={premedication}
                    onChange={handleChange}
                    className="form-control"
                    name="investigation_note"
                    placeholder="Write Something"
                />
            </div>

            <div className="row float-right">
                <div className="text-left mt-2">
                    <MainButtonInput  onClick={addNode}> Save</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2">
                    <SecondaryButtonInput  onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>
           
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}