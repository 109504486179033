import React, { useState, useEffect } from "react";
import avatarImage from '../../../img/profile.png'
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import AuthApi from "../../../services/AuthApi";
import uuid from "uuid";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Toaster from "../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import SubscriptionApi from "../../../services/SubscriptionApi";
import { useRecoilState } from "recoil";
import { setsubscriptionId } from "../../../recoil/atom/setSubscriptionId";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";

function DoctorPersonalInformation(props) {
    const { data, doctorId } = props;
    const [updateData, setUpdateData] = useState([]);
    const [subscriptionId] = useRecoilState(setsubscriptionId)
    const { subscriptionPDF } = SubscriptionApi()
    const {
        FetchDoctorData,
        submitDoctorInformation
    } = AuthApi();

    useEffect(() => {
        addDrInfo()
    }, [])

    //for all input onchange method
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdateData({ ...updateData, [name]: value });
    };

    const addDrInfo = () => {
        FetchDoctorData({ doctorId })
            .then(jsonRes => {
                setUpdateData(jsonRes)
            });

    }

    async function uploadImageAsync(uri) {
        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", uri, true);
            xhr.send(null);
        });
        const fileRef = ref(getStorage(), uuid.v4());
        const result = await uploadBytes(fileRef, blob);
        return await getDownloadURL(fileRef);
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const resultUrl = await uploadImageAsync(updateData.photo);
        const bodyData = {
            photo: resultUrl,
            name: updateData.name,
            gender: updateData.gender,
            personalEmail: updateData.personalEmail,
            address: updateData.address,
        }
        await submitDoctorInformation({ doctorId, bodyData })
            .then(() => {
                toast.success("Saved Successfully!");
            })
        // await subscriptionPDF(subscriptionId);
    }
    return (
        <>
            <form encType='multipart/form-data' onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-7 ">
                        <div className="row mb-10">
                            <div className="col-md-5">
                                <div className="doctorphoto">
                                    {updateData.photo ?
                                        <img
                                            src={updateData.photo}
                                            className="doctorphotoStyle"
                                            alt="doctorPhoto"
                                        />
                                        : <img
                                            src={avatarImage}
                                            alt="doctorPhoto"
                                            className="doctorphotoStyle"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-left">
                                    <label className="font_weight">Doctor photo</label>
                                </div>
                                <MainInput
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => {
                                        setUpdateData({ ...updateData, ['photo']: URL.createObjectURL(e.target.files[0]) })
                                    }}
                                    name="photo">
                                </MainInput>
                            </div>
                        </div>
                        <div className="text-left">
                            <label className="font_weight">Gender *</label>
                        </div>
                        <div className="col-sm-6 radioButton" align='left'>
                            <input
                                className="radio_button"
                                type="radio"
                                value='female'
                                name="gender"
                                onChange={handleInputChange}
                                checked={updateData.gender === 'female' ? true : false}
                            />
                            <span>Female</span>
                            <input
                                className="radio_button"
                                type="radio"
                                value='male'
                                name="gender"
                                checked={updateData.gender === 'male' ? true : false}
                                onChange={handleInputChange}

                            />
                            <span>Male</span>
                            <input
                                className="radio_button"
                                type="radio"
                                value='other'
                                name="gender"
                                checked={updateData.gender === 'other' ? true : false}
                                onChange={handleInputChange}
                            />
                            <span>Other</span>
                        </div>
                        <div className="text-left">
                            <label className="font_weight">Namespace *</label>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <MainInput
                                    type="text"
                                    value={updateData.namespace}
                                    name="namespace"
                                    onChange={handleInputChange}
                                    placeholder="namespace">
                                </MainInput>
                            </div>
                            <div className="text-left col-md-9">
                                <span>.wecurify.com</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="text-left">
                            <label className="font_weight" htmlFor="name">Full Name *</label>
                        </div>
                        <MainInput
                            name="name"
                            value={updateData.name}
                            onChange={handleInputChange}
                            placeholder="Name">
                        </MainInput>
                        <div className="text-left">
                            <label className="font_weight" htmlFor="email">EmailId *</label>
                        </div>
                        <MainInput
                            type="email"
                            value={updateData.personalEmail}
                            name="personalEmail"
                            onChange={handleInputChange}
                            placeholder="emailId">
                        </MainInput>

                        <div className="text-left">
                            <label className="font_weight" htmlFor="address">City & Area *</label>
                        </div>

                        <div align='left'>
                            <MainInput
                                name='address'
                                value={updateData.address}
                                placeholder="Address"
                                onChange={handleInputChange}
                            >
                            </MainInput>
                        </div>
                    </div>
                </div>
                <div className="row float-right">
                    <div className="text-left">
                        <MainButtonInput> Save</MainButtonInput>
                    </div>
                    <div className="text-left ml-2">
                        <SecondaryButtonInput onClick={data}>Next</SecondaryButtonInput>
                    </div>
                </div>
            </form>
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}
export { DoctorPersonalInformation }