import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { MainNav } from '../../mainComponent/mainNav';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from './partial/uselinks';
import { MainTabs } from '../../mainComponent/mainTabs';
import { TabPanel } from "../../common/tabpanel";
import CompletedAppointment from './partial/CompletedApt';
import CancelledAppointment from './partial/CancelledApt';
import IncompleteAppointment from './partial/IncompleteApt';
import DuePayments from './partial/DuePayments';

export default function PatientsClinicHistory() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Wrapper>
            <>
                <MainNav>
                    <ul className="clearfix">
                        <li className='float-none' style={{ fontSize: 'inherit' }}>Appointments History</li>
                    </ul>
                </MainNav>
                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width_84 common_box'>
                        <div className=" ">
                            <MainTabs
                                value={value}
                                onChange={handleChange}
                                label="Completed"
                                label1="Cancelled"
                                label2="InComplete"
                                label3="Due Payments"
                            >
                            </MainTabs>

                            <TabPanel value={value} index={0}>
                                <CompletedAppointment doctorId={doctorId} />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <CancelledAppointment doctorId={doctorId} />
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <IncompleteAppointment doctorId={doctorId} />
                            </TabPanel>

                            <TabPanel value={value} index={3}>
                                <DuePayments onChange={()=>setValue(0)} doctorId={doctorId} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </>
        </Wrapper>
    )
}