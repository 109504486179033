import React, { useEffect, useState } from 'react'
import ReportApi from '../../../services/ReportApi';
import Toaster from '../../Toaster';
import "react-toastify/dist/ReactToastify.css";
import { toast } from 'react-toastify';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';

export default function Investigation(props) {
    const [investigation_note, setInvestigation_note] = useState("")
    const [investigation, setInvestigation] = useState('')
    const { onChange, reportId } = props;
    const { insertInvestigationNote, getMedicineReport } = ReportApi();

    useEffect(() => {
        investigationData()
    }, [])

    const handleChange = (event) => {
        setInvestigation_note(event.target.value);
    }

    const addNode = () => {
        const bodyData = {
            "investigation_note": investigation_note,
        }
        insertInvestigationNote({ reportId }, bodyData)
            .then(() => {

            })
        toast.success("Saved Successfully!")
    }
    const investigationData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setInvestigation(res[0].investigation_note)
            })
    }

    return (
        <div>
            <div  align='left' className="mb-2" >
                <span className='mb-2'>Doctor Investigation Note</span>
                <textarea
                    type="text"
                    value={investigation}
                    onChange={handleChange}
                    className="form-control"
                    name="investigation_note"
                    placeholder="write something"
                />
            </div>

            <div className="row float-right">
                <div className="text-left mt-2">
                    <MainButtonInput onClick={addNode}> Save</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2 mb-5">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>
            
            <div className="row float-right">
                <Toaster />
            </div>
        </div>
    )
}