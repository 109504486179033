import './App.css';
import React, { useEffect } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import MainContainer from './MainContainer';
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import Toaster from './doctor/Toaster';
import { generateToken, messaging } from './firebase.config'
import { listenForMessages } from './firebase.config';
import { toast } from 'react-toastify';
const fbConfig = require("./firebase.config")

function App() {
  useEffect(() => {
    generateToken();
    listenForMessages(messaging, (payload) => {
      toast(payload.notification.body)
    })
  }, [])

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
}
  return (
    <div className="App">
      <Router>
        <Header />
        <MainContainer></MainContainer>
        <Toaster />
        <Outlet />
        <Footer />
      </Router>
    </div>
  );
}
export default App;
