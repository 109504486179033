import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setPatientGeneralInfo } from '../../../recoil/atom/setPatientGeneralInfo';

const FetchPatientGeneralInfo = (props) => {
    const { reportId } = props;
    const { getMedicineReport } = ReportApi();
    const [generalInfo, setGeneralInfo] = useRecoilState(setPatientGeneralInfo)
    const [bmi, setBmi] = useState(0);

    useEffect(() => {
        generalData()
    }, [])

    const generalData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setGeneralInfo(res[0])
                setBmi(res[0].BMI)
            })
    }

    return (
        <>
            {generalInfo ?
                <div className='col-md-6'>
                    <div className="patientDataStyle">
                        <div className='row'>
                            <span className='col-md-6'>
                                {generalInfo.age ?
                                    <>
                                        <label className="mx-2 patientName ">Age </label>
                                        <span>{generalInfo.age}</span>
                                    </>
                                    : null}
                            </span>
                            <span className='col-md-6'>
                                {generalInfo.weight ?
                                    <>
                                        <label className="mx-2 patientName ">Weight </label>
                                        {generalInfo.weight}
                                    </>
                                    : null}
                            </span>
                        </div>

                        <div className='row' >
                            <span className='col-md-6'>
                                {generalInfo.height ?
                                    <>
                                        <label className="mx-2 patientName ">Height </label>
                                        {generalInfo.height}
                                    </>
                                    : null}
                            </span>
                            <span className='col-md-6'>
                                {generalInfo.BMI ?
                                    <>
                                        <label className="mx-2 patientName ">BMI </label>
                                        {generalInfo.BMI}
                                    </>
                                    : null}
                            </span>
                        </div>

                        <div className='row'>
                            <span className='col-md-6'>
                                {generalInfo.temp ?
                                    <>
                                        <label className="font_weight mx-2">Temperature :</label>
                                        {generalInfo.temp}
                                    </>
                                    : null}
                            </span>
                            <span className='col-md-6'>
                                {generalInfo.bp ?
                                    <>
                                        <label className="mx-2 patientName ">Blood Pressure </label>
                                        {generalInfo.bp}
                                    </>
                                    : null}
                            </span>
                        </div>

                        <div className='row'>
                            <span className='col-md-6'>
                                {generalInfo.pulse ?
                                    <>
                                        <label className="mx-2 patientName ">Pulse </label>
                                        {generalInfo.pulse}
                                    </>
                                    : null}
                            </span>
                            <span className='col-md-6'>
                                {generalInfo.problem ?
                                    <>
                                        <label className="mx-2 patientName ">Problem </label>
                                        {generalInfo.problem}
                                    </>
                                    : null}
                            </span>
                        </div>
                    </div>
                </div> : null
            }
        </>
    )


}
export default FetchPatientGeneralInfo;