import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { Modal } from 'react-bootstrap';
import PatientGeneralInfo from './PatientGeneralInfo';
import FetchPatientGeneralInfo from './FetchPatientGeneralInfo';
import EditPatientGeneralInfo from './EditPatientGeneralInfo';

export default function PatientPersonalInfo(props) {
    const { reportId, onChange } = props;
    const { getMedicineReport } = ReportApi()
    const [generalInfo, setGeneralInfo] = useState(null)
    const [bmi, setBmi] = useState(0);
    const [show, setShow] = useState(false);
    const [editShow, setEditShow] = useState(false);

    useEffect(() => {
        generalData()
    }, []);

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = (id) => {
        setShow(true)
    }
    const handleEditClose = () => {
        setEditShow(false)
    }
    const handleEditShow = (id) => {
        setEditShow(true)
    }

    const generalData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setGeneralInfo(res[0])
                setBmi(res[0].BMI)
            })
    }

    return (
        <div>
            <div className="row float-right" >
                {generalInfo && generalInfo.age ?
                    <div className="text-left mr-3">
                        <MainButtonInput onClick={handleEditShow}>Edit </MainButtonInput>
                    </div> :
                    <div className="text-left mr-3">
                        <MainButtonInput onClick={handleShow}>Add Information</MainButtonInput>
                    </div>
                }
                <div className="text-left ">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>

            {generalInfo && generalInfo.age ?
                <FetchPatientGeneralInfo reportId={reportId} />
                : null}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >Add Patient Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PatientGeneralInfo
                        handleClose={handleClose}
                        reportId={reportId}
                        onChange={onChange}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={editShow} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title >Edit Patient Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditPatientGeneralInfo
                        handleClose={handleEditClose}
                        reportId={reportId}
                        onChange={onChange} />
                </Modal.Body>
            </Modal>
        </div>
    )

}
