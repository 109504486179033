import AccessTimeRounded from "@mui/icons-material/AccessTimeRounded"
import moment from "moment"
import { useEffect, useState } from "react";
import AppointmentsApi from "../../../services/AppointmentsApi";
import { FaClinicMedical } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";

export default function CancelledAppointment(props) {
    const { doctorId } = props
    const [patientHistoryData, setPatientHistoryData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getAppointmentsDetails } = AppointmentsApi()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPatientHistory(currentPage);
    }, [currentPage]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    const pageSize = 6;
    function getPatientHistory(currentPage) {
        setIsLoading(true); // Set loading to true before fetching data
        getAppointmentsDetails(doctorId, currentPage, pageSize)
        .then((result) => {
            const totalPages = result.totalCancelledPages;
            setTotalPages(totalPages)
            setPatientHistoryData(result.cancelled)
        })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false); 
            });
    } 

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }



    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {patientHistoryData && patientHistoryData.length > 0 ?
                            <>
                                <div className='row'>
                                    {patientHistoryData && patientHistoryData.map((details, i) => {
                                        return (
                                            <>
                                                {!details.dependentId ?
                                                    <div key={i} className="col-md-4">
                                                        <div className="cardDiv">
                                                            <span className='cardSpan'>
                                                                <i className='icon-user color patientListIcon' />
                                                                <span className='patientName'>{details['patientDetails'][0].name}</span>
                                                            </span>
                                                            <span className='cardSpan'>
                                                                <i className='icon-mobile-1 color patientListIcon' />
                                                                <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                            </span>
                                                            <span className='cardSpan '>
                                                                <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                                <span className='patinetInfo '> {details['clinicList'][0].clinicName}</span>
                                                            </span>
                                                            <span className='cardSpan time'>
                                                                <i className='pe-7s-date m-1 color patientListIcon' />
                                                                <span className='slotTime'>
                                                                    {moment(details.selectedDate).format('YYYY-MM-DD').toString()},
                                                                    {details.slotTime}
                                                                </span>
                                                            </span>
                                                            <span className='cardSpan'>
                                                                <AccessTimeRounded style={{ fontSize: 22, marginTop: 5, marginLeft: 2, color: '#1a3c8b' }} />
                                                                {details.timeSlot} Min.
                                                            </span>

                                                        </div>
                                                    </div>
                                                    : <div key={i} className="col-md-4 ">
                                                        <div className="cardDiv">
                                                            <div className='cardSpan row'>
                                                                <div align='left' className='width_70' >
                                                                    <i className=' icon-user color patientListIcon' />
                                                                    <span className=' patientName'>{details['dependentDetails'][0].name}</span>
                                                                </div>
                                                                <div className='width_10' align='right'>
                                                                    <span className='dependent'>Dependent</span>
                                                                </div>
                                                            </div>
                                                            <span className='cardSpan'>
                                                                <i className='icon-mobile-1 color patientListIcon' />
                                                                <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                            </span>
                                                            <span className='cardSpan '>
                                                                <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                                <span className='patinetInfo '> {details['clinicList'][0].clinicName}</span>
                                                            </span>
                                                            <span className='cardSpan time'>
                                                                <i className='pe-7s-date m-1 color patientListIcon' />
                                                                <span className='slotTime'>
                                                                    {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                                </span>
                                                            </span>
                                                            <span className='cardSpan'>
                                                                <AccessTimeRounded style={{ fontSize: 22, marginTop: 5, marginLeft: 2, color: '#1a3c8b' }} />
                                                                {details.timeSlot} Min.
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )

                                    })}
                                </div>
                                <div className="marginleft">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="Next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={totalPages}
                                        previousLabel="< Previous"
                                        renderOnZeroPageCount={null}
                                        marginPagesDisplayed={2}
                                        containerClassName="pagination "
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            </>
                            :
                            <div className="clinicHistory font_weight" >Appointments are not available</div>
                    }
                </>
            }
        </>
    )
}