import { useEffect, useState } from "react";
import AppointmentsApi from "../../../services/AppointmentsApi";
import moment from 'moment';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Link } from "react-router-dom";
import { FaClinicMedical } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";


export default function CompletedAppointment(props) {
    const { doctorId } = props;
    const [patientHistoryData, setPatientHistoryData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getAppointmentsDetails, downloadPrescription } = AppointmentsApi()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPatientHistory(currentPage);
    }, [currentPage])

    const pageSize = 6;
    function getPatientHistory(currentPage) {
        setIsLoading(true);
        getAppointmentsDetails(doctorId, currentPage, pageSize)
            .then((result) => {
                setPatientHistoryData(result.completed);
                setTotalPages(result.totalCompletedPages);
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false); 
            });
    } 
    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    const downloadPdf = (details) => {
        const reportId = details.medicalReportId
        downloadPrescription(reportId)
            .then((result) => {
                let alink = document.createElement('a');
                alink.href = result;
                alink.setAttribute("target", "_blank")
                alink.download = 'Prescription.pdf';
                alink.click();
            })
    }

    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {patientHistoryData && patientHistoryData.length > 0 ?
                        <>
                            <div className='row'>
                                {patientHistoryData && patientHistoryData.map((details, i) => {
                                    return (
                                        <>
                                            {details && !details.dependentId ?
                                                <div key={i} className="col-md-4">
                                                    <div className="cardDiv">
                                                        <span className='cardSpan'>
                                                            <i className='icon-user color patientListIcon' />
                                                            <span className='patientName'>
                                                                {details['patientDetails'][0].name}
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            {details['patientDetails'][0].mobile}
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className='color patientListIcon ml-1 mr-2'><FaClinicMedical /></i>
                                                            {details['clinicList'][0].clinicName}
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <AccessTimeRoundedIcon style={{ fontSize: 22, marginTop: 5, marginLeft: 2, color: '#1a3c8b' }} />
                                                            {details.timeSlot} Min.
                                                        </span>

                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3">
                                                                <Link to={`report/${details.medicalReportId}`}>
                                                                    <MainButtonInput>View</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                            <div className='mt-3 ml-2'>
                                                                <Link to="#" onClick={() => downloadPdf(details)}>
                                                                    <SecondaryButtonInput>Print</SecondaryButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <div className='cardSpan row'>
                                                            <div align='left' className='width_70' >
                                                                <i className=' icon-user color patientListIcon' />
                                                                <span className=' patientName'>{details['dependentDetails'][0].name}</span>
                                                            </div>
                                                            <div className='width_10' align='right'>
                                                                <span className='dependent'>Dependent</span>
                                                            </div>
                                                        </div>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            {details['patientDetails'][0].mobile}
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className='icon-hospital-1 color patientListIcon' />
                                                            {details['clinicList'][0].clinicName}
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>
                                                        <span className=' cardSpan'>
                                                            <AccessTimeRoundedIcon style={{ fontSize: 25, marginTop: 4, marginLeft: 2, color: '#1a3c8b' }} />
                                                            {details.timeSlot} Min.
                                                        </span>
                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3">
                                                                <Link to={`report/${details.medicalReportId}`}>
                                                                    <MainButtonInput>View</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                            <div className='mt-3 ml-2'>
                                                                <Link to="#" onClick={() => downloadPdf(details)}>
                                                                    <SecondaryButtonInput>Print</SecondaryButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </>
                                    )

                                })}
                            </div>
                            <div className="marginleft">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>
                        </> :
                        <div className="clinicHistory font_weight" >Appointments are not available</div>
                    }

                </>
            }

        </>
    )
}