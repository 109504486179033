import React, { useEffect, useState } from "react";
import { slots } from "../common/constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { useRecoilState } from "recoil";
import PatientApi from "../services/PatientApi";
import { Button, Modal } from "react-bootstrap";
import { setDependentId } from "../recoil/atom/setDependentId";
import moment from "moment";
import { generateToken } from "../firebase.config";
import AuthApi from "../services/AuthApi";
import Toaster from "../doctor/Toaster";

const ShowInClinicAppointSlots = (props) => {
    const { sessionSlot, selectedDate, session, slotDate } = props;
    const [patientId, setPatientsId] = useRecoilState(setNewPatientId)
    const [dependentId, setDependentsId] = useRecoilState(setDependentId)
    const [bookingSlots, setBookingSlots] = useState([]);
    const [show, setShow] = useState(false);
    const [bookSlot, setbookSlot] = useState([]);
    const { paymentInfo, getbookedSlots } = PatientApi();
    const { notifyDoctor } = AuthApi();
    const navigate = useNavigate();

    useEffect(() => {
        availableSlots()
    }, [props])

    const handleShow = (item) => {
        setShow(true)
        setbookSlot(item)
    }
    const handleClose = () => setShow(false)

    const handleSelectedSlot = (item) => {
        const startDate = (selectedDate + " " + item.time)
        const transactionData = {
            "DoctorId": session.doctorId,
            "ClinicId": session.clinicId,
            "slotId": item._id,
            "patientId": patientId,
            "dependentId": dependentId !== " " ? dependentId : null,
            "transactionId": '123',
            "currency": 'INR',
            "fees": session.fees,
            "date": slotDate,
            "day": session.day,
            "slotTime": item.time,
            "daySlotId": session._id,
            "selectedDate": selectedDate,
            "timeSlot": session.timeSlot,
            "startDate": startDate,
            "status": "Ongoing",
            "payment": "hold"
        }
        paymentInfo(transactionData)
            .then((res) => {
                const bookingId = (res._id)
                navigate(`/appointments/${session.doctorId}`);
                setDependentsId(' ')
                setPatientsId(' ')
            })
            .catch((error) => {
                console.error('Payment error:', error);
            });
        requestToken(patientId, session.doctorId, item.time)
        handleClose();
    }

    const requestToken = (patientId, doctorId, time) => {
        const userType = {
            doctor: 'doctor',
            patient: 'patient'
        }
        generateToken(userType.doctor)
            .then((doctorToken) => {
                if (!doctorToken) {
                    console.error('Failed to get doctor token.');
                    return;
                }
                // Generate token for the patient
                generateToken(userType.patient)
                    .then((patientToken) => {
                        if (!patientToken) {
                            console.error('Failed to get patient token.');
                            return;
                        }
                        // Once both tokens are obtained, send the notification
                        sendNotification(doctorId, patientId, patientToken, doctorToken, userType, time, selectedDate);
                    })
                    .catch((error) => {
                        console.error('Error generating patient token:', error);
                    });
            })
            .catch((error) => {
                console.error('Error generating doctor token:', error);
            });
    };

    const sendNotification = async (doctorId, patientId, patientToken, doctorToken, userType, time, selectedDate) => {
        const notificationData = {
            title: "New Appointment Booked",
            doctorId: doctorId,
            patientToken: patientToken,
            doctorToken: doctorToken,
            patientId: patientId,
            selectedDate: selectedDate,
            time: time,
            userType: userType
        };
        try {
            await notifyDoctor(notificationData)
                .then((res) => {
                    alert(res.notificationData.notification[0].body)
                    console.log('Notification sent:', res);
                })
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };


    const checkSlotAvailability = (slot) => {
        const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm")
        const slotDateTime = moment(new Date(selectedDate)).format("YYYY-MM-DD") + " " + slot.time
        const returnData = currentDate > slotDateTime || bookingSlots.some(func => (func.slotId === slot._id && func.status !== "Cancelled"))
        return returnData
    }

    const availableSlots = () => {
        getbookedSlots(session.doctorId, session.clinicId)
            .then((result) => {
                if (result) {
                    const data = result.filter((item) => {
                        if (item.date === slotDate)
                            return item
                    })
                    setBookingSlots(data)
                } else {
                    return null
                }
            })
    }

    return (
        <div style={{ flexWrap: 'wrap' }}>
            <span className="font_weight" style={{ color: "black" }}>
                {slotDate}&nbsp;
                Fees - <FaRupeeSign /> {session.fees} /-
            </span>
            <section className=" radiobutton">
                {sessionSlot.map((item, index) => (
                    <div key={index}>
                        {(checkSlotAvailability(item))
                            ?
                            <div>
                                <div
                                    className="disabled-div"
                                    type="radio"
                                    time={slots}>
                                    <label>{item.time}</label>
                                </div>
                            </div>
                            :
                            <div>
                                <Link
                                    to='#'
                                    onClick={() => handleShow(item)}
                                    className="btn_1"
                                    type="radio"
                                    time={slots}>
                                    <label>{item.time}</label>
                                </Link>
                            </div>
                        }
                    </div>
                ))}
            </section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to book this appointment. </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleSelectedSlot(bookSlot)}>
                        Yes
                    </Button>
                    <Button variant="default" style={{ border: '1px solid #1a3c8b' }} onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Toaster />
        </div>
    )
}
export { ShowInClinicAppointSlots }