import React, { useEffect, useState } from "react";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import ClinicApi from "../../../../services/ClinicApi";
import { Modal } from "react-bootstrap";
import { AddClinic } from "./addclinic";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

const SearchClinic = (props) => {
    const { doctorId } = props
    const [coilDoctorClinicData, setCoilDoctorClinicData] = useRecoilState(setDoctorClinic)
    const [clinicInfo, setClinicInfo] = useState([]);
    const [clinicSave, setClinicSave] = useState('')
    const { getClinic, addClinic } = ClinicApi()
    const [show, setShow] = useState(false);

    useEffect(() => {
        fetchclinic()
    }, [])

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onClinicFormSubmit = () => {
        handleClose();
    };

    const fetchclinic = (() => {
        getClinic()
            .then((res) => {
                setClinicInfo(res.result)
            })
    })

    const handleChange = (event, selectedValue) => {
        event.preventDefault()
        setClinicSave(selectedValue)
    }

     function sendClinicInfo(e) {
        e.preventDefault();
        const newClinicData = {
            clinicId: clinicSave._id
        }
        addClinic(newClinicData, doctorId)
            .then((res) => {
                setCoilDoctorClinicData(coilDoctorClinicData.concat(clinicSave))
            });
        props.onSubmit()
        setShow(false)
    }

    return (
        <div className="width_100">
            <form onSubmit={sendClinicInfo}>
                <div className="form-group">
                    <label className="font_weight">Search Clinic</label>
                    <div className="row">
                        <div className="col-md-8">
                            <Autocomplete
                                // id={clinicInfo._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                autoHighlight
                                value={clinicSave.clinicName}
                                onChange={handleChange}
                                getOptionLabel={(data) => `${data.clinicName} (${data.address} )`}
                                renderOption={(props, option) =>
                                (<Box component="li"sx={{ mr: 2, flexShrink: 0 }}{...props}>
                                    {option.clinicName + '(' + option.address + ')'}
                                </Box>)}
                                options={clinicInfo}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Enter Clinic Name"
                                    />
                                }
                            />
                        </div>
                        <div className="col-lg-3 margin_top_10" align='right'>
                            <MainButtonInput className='col-md-4 marginLeft ' value="Add" >
                                ADD
                            </MainButtonInput>
                        </div>
                    </div>
                </div>
            </form >
            <div className=" margin_top_30 " align='right'>
                <MainButtonInput onClick={handleShow} >ADD ANOTHER CLINIC </MainButtonInput>
            </div>
            <div className="modalbtn">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Clinic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddClinic
                            onClose={props.onSubmit}
                            doctorId={doctorId}
                            onSubmit={onClinicFormSubmit}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </div >
    );
};
export { SearchClinic }
