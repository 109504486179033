const withRadioCheckboxes = (WrappedComponent) => {
    return ({ selectedValue, onChange, ...props }) => {
        return (
          <WrappedComponent
            selectedValue={selectedValue}
            onChange={onChange}
            {...props}
          />
        );
      };
};

export default withRadioCheckboxes;
