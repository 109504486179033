import React, { useEffect, useState } from "react";
import { ShowDoctorInClinicAppointment } from "./showDoctorInClinicAppointment";
import PatientApi from "../services/PatientApi";

const DoctorAppointmentType = (props) => {
    const { doctorId } = props;
    const clinicId = props.clinicData._id;
    const [clinicSession, setClinicSession] = useState([]);
    const { fetchSessionSlotsData } = PatientApi();
    useEffect(() => {
        fetchSessionSlots();
    }, [])

    async function fetchSessionSlots() {
        fetchSessionSlotsData({ doctorId, clinicId })
            .then((result) => {
                setClinicSession(result)
            })
    }

    return (
        <div>
            {clinicSession && clinicSession.length > 0 ? (
                <ShowDoctorInClinicAppointment
                    doctorId={doctorId}
                    clinicId={clinicId}
                    setSessions={clinicSession}
                />
            ) :
                <div className="font_weight"
                    style={{ color: "black", marginTop: '10px' }}>
                    Slots are not available
                </div>
            }
        </div>
    )
}
export { DoctorAppointmentType }