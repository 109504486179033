import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import GetLabPrescription from './getLabPrescription';
import ReportApi from '../../../services/ReportApi';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { useRecoilState } from 'recoil';
import { setLabFetchData } from '../../../recoil/atom/setLabFetchData';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';

export default function LabPrescription(props) {
    const { onChange, reportId } = props
    const { getLabData, insertLabPrescriptionData } = ReportApi()
    const [labTestData, setLabTestData] = useState([]);
    const [getlabData, setGetLabData] = useRecoilState(setLabFetchData);
    const [saveLabData, setSaveLabData] = useState('')
    useEffect(() => {
        getLabTestData();
    }, [])

    const getLabTestData = () => {
        getLabData()
            .then((result) => {
                setLabTestData(result)
            })
    };
    const handleDataSave = (e, selectedData) => {
        e.preventDefault()
        setSaveLabData(selectedData)
    }
    const labDataSave = () => {
        const bodyData = {
            "reportId": reportId,
            "test_name": saveLabData.test_name
        }
        insertLabPrescriptionData(bodyData)
        .then((result)=>{
            setGetLabData(getlabData.concat(result))
        })
        // onChange()
        toast.success("Saved Successfully!")
    }

    return (
        <>
            <div className='row' >
                    <div className='col-md-3'>
                        <label className='left'>Lab Tests</label>
                        <Autocomplete
                            id={labTestData._id}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            onChange={handleDataSave}
                            getOptionLabel={(option) => `${option.test_name}`}
                            options={labTestData}
                            renderInput={(params) =>
                            (<TextField {...params}
                                label="Choose Test Name"
                            />)}
                        />
                    </div>

                <div className='col-md-8 '>
                    <GetLabPrescription reportId={reportId} />
                </div>

            </div>
            <div className="row float-right">
                <div className="text-left">
                    <MainButtonInput onClick={labDataSave}> Save</MainButtonInput>
                </div>
                <div className="text-left ml-2">
                    <SecondaryButtonInput  onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}