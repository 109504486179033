import React, { useState } from "react";
import { FetchExperience } from "./Partial/fetchExperience";
import { AddDoctorProfessionalExperience } from "./Partial/addDoctorProfessionalExperience";
import { Link } from 'react-router-dom';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";
import { Modal } from "react-bootstrap";

function DoctorProfessionalExperience(props) {
    const { doctorId } = props;
    const [showExperience, setShowExperience] = useState(true);

    function handleAdd() {
        setShowExperience(!showExperience)
    }

    function handleRecordAdded() {
        setShowExperience(true)
    }

    return (
        <>
            <FetchExperience doctorId={doctorId} />
            <div className="row justify-end">
                <div className="my-2 ">
                    <Link onClick={() => handleAdd()}>
                        <MainButtonInput >Add</MainButtonInput>
                    </Link>
                </div>
                <div className="m-2">
                    <SecondaryButtonInput onClick={props.data}>Next</SecondaryButtonInput>
                </div>
            </div>

            <Modal show={showExperience === false } onHide={handleRecordAdded}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Experience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddDoctorProfessionalExperience
                        doctorId={doctorId}
                        addRecords={handleRecordAdded} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export { DoctorProfessionalExperience }