import React, { useState } from 'react';
import { FetchEducation } from "./Partial/fetchEducation";
import { AddDoctorEducation } from "./Partial/addDoctorEducation";
import { Link } from 'react-router-dom';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { Modal } from 'react-bootstrap';

function DoctorEducation(props) {
    const { doctorId } = props;
    const [showEducation, setShowEducation] = useState(true);

    function handleAdd() {
        setShowEducation(!showEducation);
    }

    function handleRecordAdded() {
        setShowEducation(true)
    }

    return (
        <>
            <FetchEducation doctorId={doctorId} />

            <div className="row justify-end">
                <div className="my-2 ">
                    <Link onClick={() => handleAdd()}>
                        <MainButtonInput>Add</MainButtonInput>
                    </Link>
                </div>
                <div className="m-2 ">
                    <SecondaryButtonInput onClick={props.data}>Next</SecondaryButtonInput>
                </div>
            </div>

            <Modal show={showEducation === false } onHide={handleRecordAdded}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Experience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddDoctorEducation
                        doctorId={doctorId}
                        addRecords={handleRecordAdded} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export { DoctorEducation }