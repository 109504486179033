import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { setDoctorId } from '../../../recoil/atom/setDoctorId';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../../Dashboard-card/partial/uselinks';
import { Wrapper } from '../../../mainComponent/Wrapper';
import ReportApi from '../../../services/ReportApi';
import { Modal } from 'react-bootstrap';
import Payment from './Payment';
import PrescriptionSummary from './PrescriptionSummary';

export default function Summary() {
    const { reportId } = useParams();
    const { getMedicineReport } = ReportApi();
    const [helpersData] = useRecoilState(setHelperData)
    const [doctorId] = useRecoilState(setDoctorId)
    const [appointmentId, setAppointmentId] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getMedicineReportData()
    }, [])

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                if(res){
                    setAppointmentId(res[0].patientAppointmentId)
                }
            })
    }
    const onSubmit = () => {
        handleClose();
    };
    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/appointments/${doctorId}/consultation/${reportId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>Summary</li>
                </ul>
            </MainNav>
            <div className="row">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box ">
                        <div align='right'>
                            <input
                                type="submit"
                                className="btn_1 medicinebtn"
                                value="Make Payment"
                                onClick={handleShow}
                            />
                        </div>
                        <PrescriptionSummary reportId={reportId} />
                    </div>

                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Payment 
                        doctorId={doctorId} 
                        appointmentId={appointmentId} 
                        reportId={reportId} 
                        onSubmit={onSubmit} 
                    />
                </Modal.Body>
            </Modal>
        </Wrapper >
    )
}