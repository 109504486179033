
import React, { useEffect, useState } from "react";
import LabWorkApi from "../../../services/LabWorkApi";
import moment from 'moment';
import pdfImage from "../../../img/pdfimg.png";

export default function LabWorkView(props) {
    const { labWorkId } = props
    const { getLabWorkById } = LabWorkApi()
    const [data, setData] = useState([])
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        getLabWorkData();
    }, [])

    const getLabWorkData = () => {
        getLabWorkById(labWorkId)
            .then((res) => {
                setData(res)
                setDocuments(res.documents)
            })
    }
    
    return (
        <div className="patientDataStyle">
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2" >Work Name</label>
                </div>
                <div className="width70">
                    {data.workname}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Work Type</label>
                </div>
                <div className="width70">
                    {data.worktype}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Lab Name</label>
                </div>
                <div className="width70">
                    {data.labname}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Lab Contact</label>
                </div>
                <div className="width70">
                    {data.labcontact}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Patient Name</label>
                </div>
                <div className="width70">
                    {data.patient}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Clinic Name</label>
                </div>
                <div className="width70">
                    {data.clinic}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tooth Number</label>
                </div>
                <div className="width70">
                    {data.toothnumber}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Given Date</label>
                </div>
                <div className="width70">
                    {moment(data.givendate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Receive Date</label>
                </div>
                <div className="width70">
                    {moment(data.recievedate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Price</label>
                </div>
                <div className="width70">
                    {data.price}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Comment</label>
                </div>
                <div className="width70">
                    {data.comments}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Documents</label>
                </div>
                <div className="width70">
                    <div >
                        {(/\.(gif|jpe?g|png)$/i).test(documents)? (
                            <img
                                src={documents}
                                className="documentStyle"
                                name="document"
                                accept="image/*"
                                alt="doctorDocument"
                            />
                        ) : (
                            <img
                                src={pdfImage}
                                className="documentStyle"
                                name="document"
                                accept="image/*"
                                alt="doctorDocument"
                            />
                        ) }
                    </div> 
                </div>
            </div>
        </div>
    )
}