import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import AuthApi from "../../../services/AuthApi";
import { MainInput } from "../../../mainComponent/mainInput";
import { ShowLoginOtp } from "./showLoginOtp";

export default function ForgotDrMpin() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [loginData, setLoginData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const { ForgetMpinLogin } = AuthApi();

    const getOTPSection = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError(true)
        }
        else {
            ForgetMpinLogin({ mobile })
                .then(response => {
                    if (response.data.isLoggedIn === true) {
                        alert(response.data.otp)
                        setIsError(false)
                        setShowOTP(true)
                    }
                    else {
                        setIsError(true)
                    }
                    let item = response.data
                    setLoginData(item)

                })
        }
    };

    return (
        <main>
            <div className="bg_color_2">
                <div className="container margin_60_35">
                    <div id="login-2">
                        <h1>Reset Mpin</h1>
                        <form >
                            <div className=" clearfix">
                                <div className=" last">
                                    <div className="row">
                                        <lable className="mb-2 fontSize">Mobile Number</lable>
                                        <div className="col-md-12">
                                            <MainInput
                                                name="mobile"
                                                value={mobile.mobile}
                                                maxLength={10}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Phone Number (+XX)" >
                                            </MainInput>
                                        </div>
                                        {showOTP === true ?
                                            <>
                                                <ShowLoginOtp loginData={loginData} />
                                                <Outlet />
                                            </>
                                            : <div className="">
                                                <MainButtonInput onClick={getOTPSection}>go</MainButtonInput>
                                            </div>
                                        }
                                    </div>
                                    {isError === true ?
                                        <div className="validation mb-2 ml-3">
                                            Please Enter Valid Mobile Number</div>
                                        : null}

                                </div>
                            </div>
                        </form>
                        <div className="mr-2 appcolor" align="right">
                            <Link to='/'>Already have account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}