import React, { useEffect, useRef } from 'react';
import crypto from 'crypto-js';
import ServicesApi from '../../../services/servicesAPI';
import AppointmentsApi from '../../../services/AppointmentsApi';
import { useNavigate } from 'react-router-dom';

// Function to load script and append in DOM tree.
const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('error in loading razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});

const RenderRazorpay = ({
  payId,
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  doctorName,
  reportId,
  appointmentId,
  doctorId,
  refId,
  totalAmount,
  pendingamount,
  paidamount,
  onChange
}) => {
  console.log(
    refId,
    paidamount,
    amount
  )
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const { paymentCapture } = ServicesApi();
  const { UpdateStatusBookingdata } = AppointmentsApi();
  const navigate = useNavigate()

  // To load razorpay checkout modal script.
  useEffect(() => {
    displayRazorpay(options);
  }, []);

  const displayRazorpay = async (options) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    );

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on('payment.submit', (response) => {
      if(paymentMethod.current){
        paymentMethod.current = response.method;
      }
    });

    // To get payment id in case of failed transaction.
    rzp1.on('payment.failed', (response) => {
      if(paymentId.current){
        paymentId.current = response.error.metadata.payment_id;
      }
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (status, payment, orderDetails = {}) => {
    const details = {
      "doctorId": doctorId,
      "orderId": orderDetails.orderId,  // amount in the smallest currency unit
      "paymentId": orderDetails.paymentId.current,
      "signature": orderDetails.signature,
      "status": status,
      "paymentMethod": "online",
      "_id": payId,
      "totalAmount": totalAmount,
      "paidamount": paidamount,
      "pendingamount":pendingamount,
      "refId": refId
    }
    paymentCapture(details)
      .then((data) => {
        const bodyData = {
          "status": status,
          "payment": payment,
          "medicalReportId": reportId,
          "paymentId": data._id,
        }
        UpdateStatusBookingdata({ appointmentId }, bodyData)
      })
      onChange()
    navigate(`/history/${doctorId}`)
  };

  // we will be filling this object .
  const options = {
    key: keyId, // key id from props
    amount, // Amount in lowest denomination from props
    currency, // Currency from props.
    name: { doctorName }, // Title for your organization to display in checkout modal
    order_id: orderId, // order id from props
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      paymentId.current = response.razorpay_payment_id;
      // Most important step to capture and authorize the payment. This can be done of Backend server.
      const succeeded = crypto.HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret).toString() === response.razorpay_signature;
      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        if (pendingamount > 0) {
          handlePayment('Partial', "Pending", {
            orderId,
            paymentId,
            signature: response.razorpay_signature,
          });
        } else {
          handlePayment('Completed', "Done", {
            orderId,
            paymentId,
            signature: response.razorpay_signature,
          });
        }
      } else {
        handlePayment('failed', "failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true,
      ondismiss: async (reason) => {
        const {
          reason: paymentReason, field, step, code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
        if (reason === undefined) {
          handlePayment('Cancelled');
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === 'timeout') {
          handlePayment('timedout');
        }
        // Reason 3 - When payment gets failed.
        else {
          handlePayment('failed', 'failed', {
            paymentReason, field, step, code,
          });
        }
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default. 
    retry: {
      enabled: false,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      color: '#1a3c8b', // Custom color for your checkout modal.
    },
  };

  return null;
};

export default RenderRazorpay;