import { useEffect, useState } from "react";
import moment from "moment";
import AppointmentsApi from "../../../services/AppointmentsApi";
import { FaClinicMedical } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { Modal } from 'react-bootstrap';
import CompletePayment from "../../Report/partial/CompletePayment";

export default function DuePayments(props) {
    const { doctorId, onChange } = props
    const [patientHistoryData, setPatientHistoryData] = useState(null)
    const [reportId, setReportId] = useState()
    const [appointmentId, setAppointmentId] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { getAppointmentsDetails } = AppointmentsApi()
    const [show, setShow] = useState(false);

    useEffect(() => {
        getPatientHistory(currentPage);
    }, [currentPage]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    const handleClose = () => setShow(false);

    const handleShow = (details) => {
        if (details) {
            setReportId(details.medicalReportId)
            setAppointmentId(details._id)
            setShow(true);
        }
    }
    const onSubmit = () => {
        handleClose();
    };
    const pageSize = 6;
    function getPatientHistory(currentPage) {
        setIsLoading(true);
        getAppointmentsDetails(doctorId, currentPage, pageSize)
            .then((result) => {
                if (result) {
                    const totalPages = result.totalPartialPages;
                    setTotalPages(totalPages)
                    setPatientHistoryData(result.partial)
                }
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {patientHistoryData && patientHistoryData.length > 0 ?
                        <>
                            <div className='row'>
                                {patientHistoryData && patientHistoryData.map((details, i) => {
                                    return (
                                        <>
                                            {!details.dependentId ?
                                                <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <span className='cardSpan'>
                                                            <i className='icon-user color patientListIcon' />
                                                            <span className='patientName'>{details['patientDetails'][0].name}</span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                            <span className='patinetInfo '> {details['clinicList'][0].clinicName}</span>
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},
                                                                {details.slotTime}
                                                            </span>
                                                        </span>
                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3 mr-2">
                                                                <Link onClick={() => handleShow(details)}>
                                                                    <MainButtonInput>Pay Now</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <div className='cardSpan row '>
                                                            <div align='left' className='width_70' >
                                                                <i className=' icon-user color patientListIcon' />
                                                                <span className=' patientName'>{details['dependentDetails'][0].name}</span>
                                                            </div>
                                                            <div className='width_10' align='left'>
                                                                <span className='dependent'>Dependent</span>
                                                            </div>
                                                        </div>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                            <span className='patinetInfo '> {details['clinicList'][0].clinicName}</span>
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>

                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3 mr-2">
                                                                <Link onClick={() => handleShow(details)}>
                                                                    <MainButtonInput>Pay Now</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )

                                })}

                            </div>
                            <div className="marginleft">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>
                        </>
                        : <div className="clinicHistory font_weight">Appointments are not available</div>}
                </>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompletePayment
                        doctorId={doctorId}
                        appointmentId={appointmentId}
                        reportId={reportId}
                        onSubmit={onSubmit}
                        onChange={onChange}
                    />
                </Modal.Body>
            </Modal>
        </>

    )
}