import React, { useEffect, useState } from "react";
import { Line, Bar } from 'react-chartjs-2';
import ServicesApi from "../../../services/servicesAPI";

export default function MonthlyReport(props) {
    const { doctorId, payments } = props;
    const [monthlyData, setMonthlyData] = useState(null);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);

    const {
        getMonthlyPayment,
    } = ServicesApi()

    useEffect(() => {
        handlePayment()
    }, [payments]);

    const getYearsAndMonths = (monthName) => {
        const currentYear = new Date().getFullYear();
        const getYears = [];
        const prevYear = currentYear - 10;
        let x = prevYear;
        while (x <= currentYear) {
            getYears.push(x);
            x++;
        }
        setYears(getYears)
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        setMonths(month)
        if (typeof monthName !== 'string' || monthName.trim() === '') {
            return null;
        }
        const monthNameFormatted = monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();
        const monthIndex = month.indexOf(monthNameFormatted);
        return monthIndex !== -1 ? monthIndex + 1 : null;
    }

    const handlePayment = () => {
        const year = payments.year || null;
        const month = getYearsAndMonths(payments.month || '');

        getMonthlyPayment(doctorId, year, month)
            .then((result) => {
                if (Array.isArray(result)) {
                    const monthlyData = transformMonthlyPaymentData(result)
                    setMonthlyData(monthlyData)
                } else {
                }
            })
    }

    const transformMonthlyPaymentData = (paymentData) => {
        const dailyTotals = new Array(31).fill(0);
        paymentData.forEach(payment => {
            // Parse the date
            const date = new Date(payment.created_at);
            const dayIndex = date.getDate(); // Get the day of the month (1-31)
            // Convert amount to number and add to the corresponding day
            const amount = parseFloat(payment.amount);
            if (!isNaN(amount) && dayIndex > 0 && dayIndex <= 31) {
                dailyTotals[dayIndex - 1] += amount; // Array index is 0-based
            }
        });

        return {
            labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()),
            datasets: [{
                label: 'Day Payments',
                data: dailyTotals,
                fill: true,
                borderColor: '#1a3c8b',
                backgroundColor: '#e1e8ed',
                tension: 0.1
            }]
        };
    };

    return (
        <div>
            <h5>Monthly Payments</h5>
            {monthlyData ? (
                <Bar
                    height={110}
                    data={monthlyData}
                />
            ) : (
                <p>Please select year and month...</p>
            )}
        </div>
    )
}