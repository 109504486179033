import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import withRadioCheckboxes from './withRadioCheckboxes';
import { withStyles } from '@mui/styles';

const CheckboxGroup = ({ selectedValue, onChange }) => {
    const StyledCheckbox = withStyles({
        root: {
          '&$checked': {
            color: '#1a3c8b', // Customize color as needed
          },
        },
        checked: {},
      })(Checkbox);

    const handleCheckboxChange = (event) => {
        onChange(event.target.value);
    };

    const data = [
        { "id": "1", "label": "10 Days" },
        { "id": "2", "label": "15 Days" },
        { "id": "3", "label": "30 Days" },
        { "id": "4", "label": "60 Days" }
    ]
    return (
        <div>
            {data.map(({id, label}) => (
                <FormControlLabel
                    key={id}
                    control={
                        <StyledCheckbox
                            checked={selectedValue === label}
                            onChange={handleCheckboxChange}
                            value={label}
                        />
                    }
                    label={label}
                />
            ))}
        </div>
    );
};

export default withRadioCheckboxes(CheckboxGroup);
