import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MainInput } from '../../../mainComponent/mainInput';
import LabWorkApi from '../../../services/LabWorkApi';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { Box } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import AppointmentsApi from '../../../services/AppointmentsApi';
import { API } from '../../../config';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { setLabworkData } from '../../../recoil/atom/setLabWorkData';
import moment from 'moment';

export default function UpdateLabWorkTable(props) {
    const { doctorId, labWorkId } = props
    const [saveWork, setSaveWork] = useState("");
    const [saveLab, setSaveLab] = useState("");
    const [savepatient, setSavePatient] = useState("");
    const [saveClinic, setSaveClinic] = useState("");
    const [saveTooth, setSaveTooth] = useState([]);
    const [WokType, setWorkType] = useState([]);
    const [patient, setPatient] = useState([]);
    const [lab, setLab] = useState([]);
    const [clinic, setClinic] = useState([]);
    const [labWokData, setLabWorkData] = useState([]);
    const [givenDate, setGivenDate] = useState('');
    const [rcvDate, setRcvDate] = useState('');
    const [selectedTeeth, setSelectedTeeth] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [labwork, setLabwork] = useRecoilState(setLabworkData)
    const [data, setData] = useState([])
    const { getWorkTypes, getlabsInfo, getLabWorkById } = LabWorkApi()
    const { getDrInfo } = AuthApi()
    const { getAppointmentsDetails } = AppointmentsApi();
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(acceptedFiles);
        }
    });

    useEffect(() => {
        getLabWorkData();
        generateOctalCombinations();
        getLabWorkData();
    }, [])

    const getLabWorkData = () => {
        getLabWorkById(labWorkId)
            .then((res) => {
                setData(res)
                setLabWorkData(res)
                setSaveTooth(res.toothnumber)
                setSaveWork(res)
                setSaveLab(res)
                setSavePatient(res.patient)
                setSaveClinic(res.clinic)
                setGivenDate(res.givendate)
                setRcvDate(res.recievedate)
            })

        getWorkTypes()
            .then((result) => {
                setWorkType(result)
            })

        getlabsInfo()
            .then((result) => {
                setLab(result)
            })

        getDrInfo({ doctorId })
            .then((result) => {
                if (result.result[0]["clinicList"]) {
                    const clinics = result.result[0]["clinicList"]
                    const clinicNames = clinics.map(item => item.clinicName);
                    setClinic(clinicNames)
                } else {
                    setClinic('')
                }
            })

        getAppointmentsDetails(doctorId)
            .then((result) => {
                if (result.test) {
                    const res = result.test.filter(function (item, index) {
                        return index === result.test.findIndex((obj) => {
                            if (item.patientId === obj.patientId)
                                return item
                        })
                    })
                    const patientNames = res.map(item => item.patientDetails[0].name);
                    setPatient(patientNames)
                } else {
                    return null
                }
            })
    };

    const addGivenDatePicker = (date) => {
        setGivenDate(date)
    }

    const addRcvDatePicker = (date) => {
        setRcvDate(date)
    }

    const handleWorkType = (event, selectedValue) => {
        event.preventDefault()
        setSaveWork(selectedValue)
    }

    const handleLab = (event, selectedValue) => {
        event.preventDefault()
        setSaveLab(selectedValue)
    }

    const handlePatients = (event, selectedValue) => {
        event.preventDefault()
        setSavePatient(selectedValue)
    }

    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const handleTooths = (event, selectedValue) => {
        event.preventDefault()
        setSaveTooth(selectedValue)
    }

    //for all input onchange method
    const handleInputChange = event => {
        const { name, value } = event.target;
        setLabWorkData({ ...labWokData, [name]: value });
    };

    function generateOctalCombinations() {
        let combinations = [];

        for (let x = 1; x <= 4; x++) {
            for (let y = 1; y <= 8; y++) {
                // Convert x and y to octal format
                let octalX = x.toString(16)
                let octalY = y.toString(16)
                combinations.push({
                    value: `${octalX} , ${octalY}`,
                });
            }
        }
        setSelectedTeeth(combinations)
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        uploadedFiles.map((file) => formData.append('documents', file.name));
        saveTooth.map((tooth) => formData.append('toothnumber', tooth));
        formData.append('doctorId', doctorId);
        formData.append('workname', labWokData.workname);
        formData.append('worktype', saveWork.worktype);
        formData.append('price', saveWork.price);
        formData.append('labname', saveLab.labname);
        formData.append('labcontact', saveLab.labcontact);
        formData.append('patient', savepatient);
        formData.append('clinic', saveClinic);
        formData.append('givendate', givenDate);
        formData.append('recievedate', rcvDate);
        formData.append('comments', labWokData.comments);

        try {
            await axios.post(`${API}/updatelabworkbyid/${labWorkId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    const data = res.data
                    const newData = labwork.map(function (d, index) {
                        if (labWorkId === d._id) {
                            return data
                        } else {
                            return d
                        }
                    })
                    setLabwork(newData)
                })
            // Handle success
        } catch (error) {
            console.error('Error uploading files: ', error);
            // Handle error
        }
        // addLabWork(formData)
        props.onSubmit()
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-md-6 ">
                    <div className='mt-2'>
                        <label className="font_weight">Work Name</label>
                        <MainInput
                            type="text"
                            value={labWokData.workname}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder='Work Name'
                            name="workname"
                        />
                    </div>

                    <div>
                        <label className="font_weight">Work type</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveWork.worktype}
                            onChange={handleWorkType}
                            getOptionLabel={(data) => `${data.worktype}`}
                            options={WokType}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.worktype}</Box>)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Work type"
                                />}
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Price</label>
                        <MainInput
                            type="text"
                            value={saveWork.price}
                            className="form-control"
                            placeholder='Price'
                            name="price"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Patient List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={savepatient}
                            onChange={handlePatients}
                            getOptionLabel={(data) => `${data}`}
                            options={patient}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"patients not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Patient Name"
                                />
                            }
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Given Date</label>
                        <div className="form-group">
                            <DatePicker
                                disabled
                                className="datepicker"
                                onChange={addGivenDatePicker}
                                value={givenDate}
                                clearIcon={null}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='mt-2'>
                        <label className="font_weight">Tooth Number</label>
                        <Autocomplete
                            multiple={true}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveTooth}
                            onChange={handleTooths}
                            getOptionLabel={(data) => `${data}`}
                            options={selectedTeeth.map((n) => `${n.value}`)}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"patients not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Select Tooth"
                                />
                            }
                        />
                    </div>

                    <div className='mt-3'>
                        <label className="font_weight">Lab Name </label>
                        <Autocomplete
                            id={lab._id}
                            autoHighlight
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveLab.labname}
                            onChange={handleLab}
                            getOptionLabel={(data) => `${data.labname}`}
                            options={lab}
                            noOptionsText={"Lab not available"}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.labname}</Box>)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Lab Name"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Contact</label>
                        <MainInput
                            type="text"
                            value={saveLab.labcontact}
                            className="form-control"
                            placeholder='Contact'
                            name="labcontact"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Clinic List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveClinic}
                            onChange={handleClinics}
                            getOptionLabel={(data) => `${data}`}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            options={clinic}
                            noOptionsText={"Clinic not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Clinic Name"
                                />
                            }
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Received Date</label>
                        <div className="form-group">
                            {rcvDate && rcvDate ?
                                <DatePicker
                                    disabled={true}
                                    className="datepicker"
                                    onChange={addRcvDatePicker}
                                    value={rcvDate}
                                    clearIcon={null}
                                />
                                :
                                <DatePicker
                                    disabled={false}
                                    className="datepicker"
                                    onChange={addRcvDatePicker}
                                    value={rcvDate}
                                    clearIcon={null}
                                />
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-2'>
                <label className="font_weight">Comment</label>
                <textarea
                    // style={{ width: 200 }}
                    type="text"
                    value={labWokData.comments}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder='Optional'
                    name="comments" />
            </div>

            <div className='mt-2'>
                <label className="font_weight">Upload Your Documents</label>
                <div id="form-file-upload" {...getRootProps()}>
                    <input {...getInputProps()} type="file" id="input-file-upload" multiple={true} />
                    <label id="label-file-upload" htmlFor="input-file-upload" className="drag-active">
                        <div>
                            {uploadedFiles.length > 0 ?
                                <ul>
                                    {uploadedFiles.map((file) => (
                                        <li key={file.name}>{file.name}</li>
                                    ))}
                                </ul>
                                :
                                <>
                                    <p>Drag and drop your file here or</p>
                                </>
                            }
                        </div>
                    </label>
                </div>
            </div>

            <div className="text-center mt-2 add_top_30">
                <MainButtonInput>Save</MainButtonInput>
            </div>
        </form>
    )
} 