import React, { useState, useEffect } from "react";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../../mainComponent/mainInput";
import ClinicApi from "../../../../services/ClinicApi";
import { Autocomplete, TextField } from "@mui/material";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import uuid from "uuid";

const EditClinic = (props) => {
    const { doctorId, clinicId, updateclinic } = props;
    const [selectedService, setSelectedService] = useState([]);
    const [clinicInfo, setClinicInfo] = useState([]);
    const [clinicList, setClinicList] = useRecoilState(setDoctorClinic);
    const [servicess, setServicess] = useState([])
    const { getServicess, editClinic, getClinicDataById } = ClinicApi()

    useEffect(() => {
        getClinic()
        fetchServicess()
    }, [])

    const fetchServicess = () => {
        getServicess()
            .then((res) => {
                setServicess(res)
            })
    }

    const getClinic = () => {
        const clinicId = updateclinic._id
        getClinicDataById({ clinicId })
            .then((res) => {
                setClinicInfo(res.data)
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClinicInfo({ ...clinicInfo, [name]: value });
    };

    const handleService = (e, selectedValue) => {
        e.preventDefault()
        setSelectedService(selectedValue)
    }

    async function uploadImageAsync(uri) {
        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", uri, true);
            xhr.send(null);
        });

        const fileRef = ref(getStorage(), uuid.v4());

        const result = await uploadBytes(fileRef, blob);

        // blob.close();
        return await getDownloadURL(fileRef);
    }

    async function sendClinicInfo(e) {
        e.preventDefault();
        const resultUrl = await uploadImageAsync(clinicInfo.clinicLogo)
        const newClinicData = {
            clinicLogo: resultUrl,
            clinicName: clinicInfo.clinicName,
            address: clinicInfo.address,
            clinicNumber: clinicInfo.clinicNumber,
            services: selectedService,
            accountNumber: clinicInfo.accountNumber,
            IFSCcode: clinicInfo.IFSCcode,
        }
        editClinic({ clinicId }, newClinicData)
            .then((res) => {
                const data = clinicList.map((clinic) => {
                    if (clinicId === clinic._id) {
                        return res
                    }
                    else {
                        return clinic
                    }
                })
                setClinicList(data)
                props.onSubmit()
            })
    }

    return (
        <div className="col-lg-12">
            <form onSubmit={sendClinicInfo}>
                <div className="text-left">
                    <label className="font_weight">Clinic Logo</label>
                    <MainInput
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                            setClinicInfo({ ...clinicInfo, ['clinicLogo']: URL.createObjectURL(e.target.files[0]) })
                        }}
                        name="clinicLogo">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">Clinic Name</label>
                    <MainInput
                        type="text"
                        name="clinicName"
                        value={clinicInfo.clinicName}
                        onChange={handleChange}
                        placeholder="Enter clinic name">
                    </MainInput>
                </div>

                <label className="font_weight">Location</label>
                <MainInput
                    type="text"
                    name="address"
                    value={clinicInfo.address}
                    onChange={handleChange}
                    placeholder="Enter clinic address">
                </MainInput>

                <label className="font_weight">Clinic Number</label>
                <MainInput
                    type="text"
                    name="clinicNumber"
                    onChange={handleChange}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    maxLength={10}
                    value={clinicInfo.clinicNumber}
                    placeholder="Clinic Number (+XX)">
                </MainInput>
                <div className="form-group">
                    <label className="font_weight">Account Number</label>
                    <MainInput
                        type="text"
                        name="accountNumber"
                        value={clinicInfo.accountNumber}
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        placeholder="Account Number">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">IFSC Code</label>
                    <MainInput
                        type="text"
                        name="ifscCode"
                        value={clinicInfo.IFSCcode}
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        placeholder="IFSC code">
                    </MainInput>
                </div>
                <div className='align-left '>
                    <div align='left' className="patientData mt-2 mb-2 font_weight">
                        Clinic Services
                    </div>
                    <Autocomplete
                        disablePortal={true}
                        disableClearable
                        disableCloseOnSelect
                        multiple={true}
                        className='autocompleteWidth'
                        id={servicess._id}
                        value={selectedService.name}
                        onChange={handleService}
                        getOptionLabel={(servicess) => `${servicess.name}`}
                        options={servicess}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Service" />}
                    />
                </div>
                <div className="text-center m-3">
                    <MainButtonInput />
                </div>
            </form >
        </div >
    );
};
export { EditClinic }
