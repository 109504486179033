import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { useRecoilState } from 'recoil';
import { setPatientGeneralInfo } from '../../../recoil/atom/setPatientGeneralInfo';

export default function EditPatientGeneralInfo(props) {
    const { reportId } = props;
    const { insertPatientVitalSignsData, getMedicineReport } = ReportApi()
    const [generalInfo, setGeneralInfo] = useRecoilState(setPatientGeneralInfo)
    const [bmi, setBmi] = useState(0);
    const [updateInfo, setUpdateInfo] = useState({})

    useEffect(() => {
        generalData()
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUpdateInfo({ ...updateInfo, [name]: value })
    }

    const calculateBmi = () => {
        const heightInMeters = updateInfo.height / 100;
        const calculatedBmi = updateInfo.weight / (heightInMeters * heightInMeters);
        setBmi(calculatedBmi.toFixed(2)); // Display BMI with 2 decimal places
    };

    const saveData = (e) => {
        e.preventDefault();
        const bodyData = {
            "age": updateInfo.age,
            "weight": updateInfo.weight,
            "height": updateInfo.height,
            "BMI": bmi,
            "temp": updateInfo.temp,
            "bp": updateInfo.bp,
            "pulse": updateInfo.pulse,
            "problem": updateInfo.problem,
        }
        insertPatientVitalSignsData({ reportId }, bodyData)
            .then((res) => {
                setGeneralInfo(res)
            })
        toast.success("Saved Successfully!")
        props.handleClose()
    }

    const generalData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setUpdateInfo(res[0])
                setBmi(res[0].BMI)
            })
    }

    return (
        <div>
            <div className="">
                <div className="row">
                    <div className="col-md-6">
                        <label className='left' >Age</label>
                        <input
                            type="text"
                            className="form-control"
                            value={updateInfo.age}
                            name="age"
                            placeholder="Age"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 ">
                        <label className='left'>Blood Pressure</label>
                        <input
                            type="text"
                            className="form-control"
                            value={updateInfo.bp}
                            name="bp"
                            placeholder="Blood Pressure"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label className='left'>Temperature</label>
                        <input
                            type="text"
                            className="form-control"
                            value={updateInfo.temp}
                            name="temp"
                            placeholder="Temperature"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className='left'>Pulse</label>
                        <input
                            type="text"
                            className="form-control"
                            value={updateInfo.pulse}
                            name="pulse"
                            placeholder="Pulse"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <label className='left'>Weight (kg)</label>
                        <input
                            type="text"
                            value={updateInfo.weight}
                            className="form-control"
                            name="weight"
                            placeholder="Weight"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className='left' >Height (cm)</label>
                        <input
                            type="text"
                            className="form-control"
                            value={updateInfo.height}
                            name="height"
                            placeholder="Height"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        {/* <label className='left mt-2'>BMI</label> */}
                        <MainButtonInput onClick={calculateBmi}>Compute BMI</MainButtonInput>
                    </div>
                    <div className="col-md-6">
                        <input
                            type="text"
                            value={bmi}
                            className="form-control"
                            name="BMI"
                            placeholder="BMI"
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <label className='left'>Problem</label>
                    <textarea
                        type="text"
                        value={updateInfo.problem}
                        onChange={handleChange}
                        className="form-control"
                        name="problem"
                        placeholder="Problem"
                    />
                </div>
                <div className="row float-right">
                    <Toaster />
                </div>
            </div>
            <div className=" float-right mt-3 mr-3">
                <MainButtonInput onClick={saveData}> Save</MainButtonInput>
            </div>
        </div >
    )

}
