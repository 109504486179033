import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { MainNav } from '../../mainComponent/mainNav';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { Wrapper } from '../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Button, Modal } from 'react-bootstrap';
import UserLinks from './partial/uselinks';
import AddLabWorkTable from './partial/Addlabworktable';
import GetLabWork from './partial/GetLabWork';
import AddWork from './modalbox/addworkmodal';
import AddLab from './modalbox/addlabmodal';

export default function AddLabWork() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [labWorkModal, setLabWorkModal] = useState(false)
    const [labModal, setLabModal] = useState(false)
    const [workModal, setWorkModal] = useState(false)
 
    const handleLabworkShow = () => setLabWorkModal(true);
    const handleLabworkClose = () => setLabWorkModal(false);

    const handleLabClose = () => setLabModal(false);
    const handleLabShow = () => setLabModal(true);

    const handleWorkClose = () => setWorkModal(false);
    const handleWorkShow = () => setWorkModal(true);

    const onFormSubmit = () => {
        handleLabClose();
        handleWorkClose();
        handleLabworkClose();
    };

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <ul className="clearfix">
                            <li className='float-none' style={{ fontSize: 'inherit' }}>Lab Work</li>
                        </ul>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            onClick={() => handleLabShow()}
                            variant="default"
                            className='appColor  mr-3 btn_sub'>
                            Add Lab
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleWorkShow()}
                            variant="default"
                            className='appColor btn_sub'>
                            Add Work
                        </Button>
                    </div>
                </div>
            </MainNav>

            <div className='row'>
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <div className='m-4' align='right'>
                            <Button className="btn_1" type="submit" onClick={() => handleLabworkShow()}>Add Lab Work</Button>
                        </div>
                        <GetLabWork doctorId={doctorId} />
                    </div>
                </div>
            </div>

            <Modal show={labWorkModal} onHide={handleLabworkClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Lab Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddLabWorkTable doctorId={doctorId} onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>

            <Modal show={labModal} onHide={handleLabClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Lab</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddLab onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>

            <Modal show={workModal} onHide={handleWorkClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddWork onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>
        </Wrapper>
    )
}
