import axios from 'axios';
import { API } from '../config';

export default function ServicesApi() {
    const addServices = async (bodydata) => {
        try {
            const result = await axios.post(`${API}/addservices`, bodydata)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const updateServices = async (serviceId, bodydata) => {
        try {
            const result = await axios.post(`${API}/updateservices/${serviceId}`, bodydata)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const deleteServices = async (serviceId) => {
        try {
            const result = await axios.delete(`${API}/deleteservices/${serviceId}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getServicesById = async (serviceId) => {
        try {
            const result = await axios.get(`${API}/getservice/${serviceId}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getServices = async (doctorId, currentPage, pageSize) => {
        try {
            const result = await axios.get(`${API}/getservices/${doctorId}?page=${currentPage}&pageSize=${pageSize}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const payment = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/razorpay/order`, bodyData)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const refund = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/refund`, bodyData)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const paymentCapture = async (details) => {
        try {
            const result = await axios.post(`${API}/paymentCapture`, details)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getPaymentDetails = async ( doctorId, currentPage, pageSize) => {
        try {
            const result = await axios.get(`${API}/paymentData/${doctorId}?page=${currentPage}&pageSize=${pageSize}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getMonthlyPayment = async ( doctorId, year, month ) => {
        try {
            const result = await axios.get(`${API}/monthlypayment/${doctorId}/${year}/${month}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getyearlyPayment = async ( doctorId, year ) => {
        try {
            const result = await axios.get(`${API}/yearlypayment/${doctorId}/${year}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const getPaymentsByAppointmentId = async ( refId ) => {
        try {
            const result = await axios.get(`${API}/payments/${refId}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    return {
        addServices,
        updateServices,
        deleteServices,
        getServicesById,
        getServices,
        payment,
        paymentCapture,
        refund,
        getPaymentDetails,
        getMonthlyPayment,
        getyearlyPayment,
        getPaymentsByAppointmentId
    }
}